@import "mixins"
.notFoundPage
  height: 100%
  overflow-y: auto
.notFoundPageContent
  background: var(--icon-not-found-bg) no-repeat center bottom
  height: 100%
  min-height: 580px
  position: relative
  > div
    display: flex
    flex-direction: column
    justify-content: flex-end
    background: var(--notFount_bg)
    width: 100%
    max-width: 330px
    margin: 0 auto
    height: calc(100% - 208px)
    > span
      margin: 0 auto -48px !important

    .notFoundLogo
      @include flex
      margin: 0 auto 110px !important
      span
        @include flex
        svg
          width: auto
          height: 34px

.leftBubble
  background: var(--icon-not-found-red-bubble) no-repeat bottom center
  background-size: 228px auto
  animation: leftBubbleAnimate 1s
  width: 228px
  height: 198px
  min-height: 198px
  margin: 20px auto 30px
  padding: 14px 20px 0
  color: rgb(var(--secondaryColor))
  display: flex
  flex-direction: column
  align-items: center

  span
    text-transform: uppercase
    @include bold

  > *
    position: relative
    animation: leftBubbleContentAnimate 1s

  > span:first-of-type
    display: inline-flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: 16px
    border-radius: 3px
    padding: 6px
    min-width: 70px
    background: #842024

    strong
      font-size: 30px

  > span:last-of-type
    font-size: 15px
    margin-top: 8px
    margin-bottom: 4px
    text-align: center
    line-height: 18px

  p
    text-align: center
    font-size: 13px
    line-height: 18px

  @keyframes leftBubbleAnimate
    0%
      background-size: 0
      opacity: 0
    40%
      background-size: 0
      opacity: 0
    80%
      background-size: 100%
      opacity: 1
    100%
      background-size: 100%
      opacity: 1
  @keyframes leftBubbleContentAnimate
    0%
      opacity: 0
    80%
      opacity: 0
    100%
      opacity: 1

.goToHome
  height: 40px
  width: 160px
  position: absolute
  bottom: 0
  left: 50%
  border-radius: 4px 4px 0 0
  transform: translateX(-50%)
  @include flex
  justify-content: center
  padding: 0 8px
  line-height: 16px
  background: rgb(var(--particularColor))
  font-size: 16px
  @include bold
  color: var(--button_color)

  &:active
    background: var(--button_primaryActiveBg)